import React, { FC, HTMLInputTypeAttribute, memo } from 'react'

import './InputLabel.scss'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    key?: string
    label?: string
    errorMessage?: string
    value: any
    onChange: (param?: any) => void
    style?: React.CSSProperties
    inputStyle?: React.CSSProperties
    classNames?: string
    inputClassName?: string
    name?: string
    type?: HTMLInputTypeAttribute
    disabled?: boolean
    autoComplete?: string
}

const InputLabel: FC<InputProps> = ({
    key,
    label,
    errorMessage,
    onChange,
    value,
    style,
    inputStyle,
    inputClassName,
    className,
    name,
    type,
    disabled = false,
    autoComplete = 'off',
    ...rest
}) => {
    const id: string | number = key ?? '1'

    return (
        <div className={`input-label ${className}`} key={key} style={style}>
            <label className="input-label__label" htmlFor={id}>
                {label}
            </label>
            <input
                disabled={disabled}
                name={name}
                className={`input-label__input ${inputClassName}`}
                value={value}
                onChange={onChange}
                style={inputStyle}
                type={type}
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="new-password"
                {...rest}
            />
            {errorMessage && <p className="input-label__error">{errorMessage}</p>}
        </div>
    )
}

export default memo(InputLabel)
